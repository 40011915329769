
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class GptSidebarPopup extends Vue {
  @Prop({ type: String, required: true }) title?: any;

  @Prop({ type: Boolean, required: false }) hideContentMargin?: any;

  @Prop({ type: String, required: false, default: 'Back To Overview' }) backText?: any;
}
